import React, { useState, useEffect } from 'react'
import Header from "../components/header"
import HeaderMenu from "../components/header-menu"
import Footer from "../components/footer"
import { Link } from 'gatsby'
import { navigate } from "gatsby"
import { getUser,logout } from "../services/auth"
import { removeItem} from "../services/session"

const MainMenu = props => {
  /* Nivell: 
      1. Access a tot
      2. Acces a sols destete engreix
      3. Acces a sols destete mares 
  */
  function signOut(event){
    event.preventDefault();
      logout()
      navigate("/login")        
  }
  
  useEffect( () => {
    if (!getUser()) {
        navigate("/login") 
      }
      console.log(" getUser().nivell.admin="+getUser().nivell.admin)
      removeItem("userGranjaTecVisites")
      window.localStorage.removeItem("userGranjaTecVisites")
    }, []);

  return (
    <span>
       
      <div className="granjatec-wrapper">
        <Header/>
        <HeaderMenu />
        <div>
      </div>
        <div className="main-container">
          <section className="section choose-opt">
                    {getUser() && getUser().nivell.maredes? (
                        <Link className="btn-primary" to="/maresDestete">Mares destete</Link>
                      ):
                      <span/>
                    }
                    {getUser() && getUser().nivell.deseng? (
                        <Link className="btn-primary" to="/desteteEngreix">Destete a engreix</Link>
                      ):
                        <span/>
                    }
                   
                    {getUser() && getUser().nivell.engescor? (
                            <Link className="btn-primary" to="/sortidaEscorxador" >Engreix a escorxador</Link>
                      ):
                      <span/>
                    }
                    {getUser() && getUser().nivell.mareescor? (
                            <Link className="btn-primary" to="/maresEscorxador" >Mares a escorxador</Link>
                      ):
                      <span/>
                    }
                    {getUser() && getUser().nivell.fitxesmares? (
                            <Link className="btn-primary" to="/fitxesMares" >Fitxa granja mares</Link>
                      ):
                      <span/>
                    }
                     {getUser() && getUser().nivell.veterinari? (
                        <Link className="btn-primary" to="/veterinaris/visitesVeterinaris">Visites veterinari</Link>
                      ):
                      <span/>
                    }
                    {getUser() && getUser().nivell.baixes? (
                        <Link className="btn-primary" to="/baixes">Baixes</Link>
                      ):
                      <span/>
                    }
                    {getUser() && getUser().nivell.admin? (
                        <Link className="btn-primary" to="/administrador/menuAdmin">Administrator</Link>
                      ):
                      <span/>
                    }
                  <Link className="btn-close" to="#" onClick={signOut}>Sortir</Link>   
          </section>
        </div>
        <Footer/>
      </div>
      </span>
  )

}

export default MainMenu